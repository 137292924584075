<template>
    <el-container class="content">
        <el-aside width="200px">
            <el-menu :default-active="activeIndex" class="menu" router @open="handleOpen" :default-openeds="defaultOpeneds" unique-opened>
                <el-submenu index="1" title>
                    <template slot="title">
                        <span>{{$lang('view.member.member106')}}</span>
                    </template>
                    <el-menu-item index="index">{{$lang('view.member.member107')}}</el-menu-item>
                    <el-menu-item index="info">{{$lang('view.member.member108')}}</el-menu-item>
                    <el-menu-item index="security">{{$lang('view.member.member109')}}</el-menu-item>
                    <el-menu-item index="delivery_address">{{$lang('view.member.member102')}}</el-menu-item>
                    <el-menu-item index="collection">{{$lang('view.member.member111')}}</el-menu-item>
                    <el-menu-item index="footprint">{{$lang('view.member.member110')}}</el-menu-item>
                </el-submenu>
                <el-submenu index="2" title>
                    <template slot="title">
                        <span>{{$lang('view.member.member111')}}</span>
                    </template>
                    <el-menu-item index="order_list">{{$lang('view.member.member113')}}</el-menu-item>
                    <el-menu-item index="activist">{{$lang('view.member.member114')}}</el-menu-item>
                    <template v-if="is_verify == 1">
                        <el-menu-item index="verification">{{$lang('view.member.member115')}}</el-menu-item>
                        <el-menu-item index="verification_list">{{$lang('view.member.member116')}}</el-menu-item>
                    </template>
                </el-submenu>
                <el-submenu index="3" title>
                    <template slot="title">
                        <span>{{$lang('view.member.member117')}}</span>
                    </template>
                    <el-menu-item index="account">{{$lang('view.member.member118')}}</el-menu-item>
                    <el-menu-item index="withdrawal">{{$lang('view.member.member119')}}</el-menu-item>
                    <el-menu-item index="my_coupon">{{$lang('view.member.member120')}}</el-menu-item>
                    <el-menu-item index="my_point">{{$lang('view.member.member121')}}</el-menu-item>
					<el-menu-item index="account_list">{{$lang('view.member.member122')}}</el-menu-item>
                    <!-- <el-menu-item index="level">会员等级</el-menu-item> -->
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-main class="member">
            <transition name="slide"><router-view /></transition>
        </el-main>
    </el-container>
</template>
<script>
    import { checkisverifier } from "@/api/order/verification"
    export default {
        name: "home",
        components: {},
        data: () => {
            return {
                defaultOpeneds: ["1"],
                activeIndex: "index",
                is_verify: 1
            }
        },
        created() {
            this.activeIndex = this.$route.meta.parentRouter ||this.$route.path.replace("/member/", "")
            this.checkIsVerifier()
        },
        methods: {
            handleOpen(key, keyPath) {
                this.defaultOpeneds = keyPath
            },
            checkIsVerifier() {
                checkisverifier()
                    .then(res => {
                        if (res.data) {
                            this.is_verify = 1
                        }
                    })
                    .catch(err => {
                        this.is_verify = 0
                    })
            }
        },
        watch: {
            $route(curr) {
				this.activeIndex = curr.meta.parentRouter||this.$route.path.replace("/member/", "");
            }
        }
    }
</script>
<style lang="scss" scoped>
    .menu {
        min-height: 730px;
    }
    .content {
        display: flex !important;
        margin: 20px 0;
    }
    .member {
        margin-left: 15px;
    }
</style>
